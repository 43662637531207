import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './components/Main';
import reportWebVitals from './reportWebVitals';

import { MsalProvider } from "./msal-context";
import { msalConfig, loginRequest } from "./Configs/auth-config";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_en from "./translations/common.en.json";
import common_fi from "./translations/common.fi.json";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'fi', // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    fi: {
      common: common_fi
    },
  },
});

root.render(
  <MsalProvider
    config={msalConfig}
    scopes={loginRequest}
  >
    <I18nextProvider i18n={i18next}>
      <Main />
    </I18nextProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
